import "@lottiefiles/lottie-player";
import { graphql, Link } from "gatsby";
import React, { Component, createRef } from "react";
// import { Col, Row } from "react-bootstrap";
// import ArrowRight from "../assets/icons/arrow-right-alt.svg";
import {
  HowToWorkWithUsSection,
  // Image,
  Layout,
  OurWorkSection,
  SEOTags,
  TechStackSection,
  WhatWeDoSection,
  WhoWeAreSection,
  WorkingWithUsSection,
} from "../components";
import * as styles from "../styles/home.module.scss";
const _ = require("lodash");

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = { disableLeftScroll: true, disableRightScroll: false };
    this.scrollRef = createRef(null);
  }

  cleanRelativeDir = (dir) => {
    const substrings = dir.split("/");
    const subdir = substrings[substrings.length - 1];
    const title = subdir.split("_")[1].replaceAll("-", " ").toUpperCase();
    return title;
  };

  render() {
    const tabClassArray = ["", styles.dataengg, styles.design, styles.devops];
    const techIcons = _(this.props.data.techIcons.nodes)
      .groupBy((tab) => tab.relativeDirectory)
      .map((value, key) => ({
        title: key,
        content: value,
      }))
      .value()
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((item, index) => ({
        title: this.cleanRelativeDir(item.title),
        className: tabClassArray[index] ?? "",
        content: item.content,
      }));

    // const portfolio = this.props.data.industryIcons.nodes
    //   .filter((_, index) => index !== 3 && index !== 5)
    //   .slice(0, 6)
    //   .map((item) => ({
    //     industry: item.name.split("_").slice(1).join(" "),
    //     publicURL: item.publicURL,
    //   }));

    const ourWork = this.props.data.portfolioMdx.nodes.map((item, index) => ({
      text: item.childMdx.body,
      industry: item.childMdx.frontmatter.title,
      publicURL: this.props.data.portfolioIcons.nodes[index].publicURL,
    }));

    return (
      <Layout layoutClassName="home-page">
        <div className={styles.homepage}>
          {/* <div className={`${styles.heroAnimation} ${styles.computer}`}>
            <lottie-player
              src="/lottie/Home-hero.json"
              autoplay
              loop
              preserveAspectRatio="xMidYMid slice"
              style={{
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
              }}
            ></lottie-player>
          </div>
          <div className={`${styles.heroAnimation} ${styles.mobile}`}>
            <lottie-player
              src="/lottie/Home-mobile-hero.json"
              autoplay
              loop
              preserveAspectRatio="xMidYMid slice"
              style={{
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
              }}
            ></lottie-player>
          </div> */}
          <section className={styles.hero}>
            <p className={styles.title}>Innovative solutions built together.</p>
            <Link to="/contact/">
              <button>Work with us</button>
            </Link>
          </section>
          <WhoWeAreSection />
          <WorkingWithUsSection />
          <WhatWeDoSection />
          <OurWorkSection data={ourWork} />
          <TechStackSection techIcons={techIcons} />
          {/* <section className={styles.portfolio}>
            <Row>
              <Col xs={12} md lg xl>
                <h6 className={styles.header}>PORTFOLIO</h6>
                <p className={styles.title}>
                  We have extensive experience working with clients from various
                  industries.
                </p>
                <Link to="/portfolio/" className={styles.arrowLink}>
                  SEE PORTFOLIO <ArrowRight />
                </Link>
              </Col>
              <Col xs={12} md={12} lg={12} xl={7}>
                <div className={styles.grid}>
                  {portfolio.map((item, index) => (
                    <div key={`portfolio-${index}`} className={styles.cell}>
                      <Image src={item.publicURL} alt={`${item.industry}`} />
                      <p className={styles.industry}>{item.industry}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </section> */}
          <HowToWorkWithUsSection />
        </div>
      </Layout>
    );
  }
}

export default Root;

export const Head = () => <SEOTags />;

export const query = graphql`
  query {
    techIcons: allFile(
      filter: { relativeDirectory: { regex: "/assets/icons/tech/(.*)/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        publicURL
        name
        relativeDirectory
      }
    }
    industryIcons: allFile(
      filter: { relativeDirectory: { eq: "assets/icons/industry" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        publicURL
      }
    }
    portfolioIcons: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "svg" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        publicURL
      }
    }
    portfolioMdx: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "mdx" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        childMdx {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
